<template>
  <div class="event-messages">
    <div class="no-messages" v-if="eventMessages.length == 0">
      <h4>No messages yet</h4>
      <p class="large">
        Messages sent by the Tournament Director will be listed here. Players
        using Tjing for iOS or Android will recieve a push notification when a
        message is posted.
      </p>
    </div>
    <div class="event-message" v-for="message in eventMessages" v-else>
      <div class="message-header">
        <p>
          <b>{{ message.subject }}</b>
        </p>
        <div
          class="read-marker"
          v-if="
            isUserLoggedIn &&
            userRegisteredToThisEvent &&
            !message.userReadReceipt
          "
        ></div>
      </div>

      <vue-markdown
        class="message-body"
        :source="message.message"
      ></vue-markdown>
      <div class="message-details">
        <div class="author-details">
          <p class="small">
            Posted: <b>{{ dateFormatter(message.createdAt) }}</b>
            <br class="responsive-linebreak" />
          </p>
          <p
            class="responsive-separator small"
            v-if="message.createdAt != message.updatedAt"
          >
            •
          </p>
          <p
            class="small updater"
            v-if="message.createdAt != message.updatedAt"
          >
            Last updated: <b>{{ dateFormatter(message.updatedAt) }}</b>
          </p>
          <p class="responsive-separator small">•</p>
          <p class="small visibility">
            Visible for
            <b>
              <template v-if="message.audience == 'PUBLIC'">everyone</template>
              <template v-if="message.audience == 'ACCEPTED'"
                >confirmed players</template
              >
              <template v-if="message.audience == 'PENDING'"
                >waiting players</template
              ></b
            >
          </p>
        </div>
        <template v-if="isUserLoggedIn && userRegisteredToThisEvent">
          <p
            @click="unMarkAsRead(message)"
            v-if="message.userReadReceipt"
            class="read-toggle small"
          >
            Mark as unread
          </p>
          <p @click="markAsRead(message)" v-else class="read-toggle small">
            Mark as read
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VueMarkdown from "vue-markdown";

export default {
  components: { VueMarkdown },
  name: "PublicEventMessages",
  data() {
    return {};
  },
  computed: {
    userRegisteredToThisEvent() {
      for (var i = 0; i < this.$store.getters.registrations.length; i++) {
        if (
          this.$store.getters.registrations[i].event.id ==
            this.$router.currentRoute.params.eventId &&
          (this.$store.getters.registrations[i].status == "ACCEPTED" ||
            this.$store.getters.registrations[i].status == "PENDING")
        ) {
          return this.$store.getters.registrations[i];
        }
      }
      return null;
    },
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    eventMessages() {
      return this.$store.getters.eventMessages;
    },
  },
  methods: {
    async unMarkAsRead(message) {
      try {
        let readReceipt = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation{
                DeleteEventMessageReadReceipt(messageId:"${message.id}")
              }
            `,
          },
        });

        message.userReadReceipt = null;
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    },
    async markAsRead(message) {
      try {
        let readReceipt = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation{
                CreateEventMessageReadReceipt(messageId:"${message.id}"){
                  id
                }
              }
            `,
          },
        });

        message.userReadReceipt =
          readReceipt.data.data.CreateEventMessageReadReceipt;
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    },
    dateFormatter(date) {
      return moment(date).format("D MMM HH:mm");
    },
  },

  beforeCreate() {},
  destroyed() {},
};
</script>


<style lang="scss">
.event-messages {
  .event-message {
    .message-body {
      padding: 12px 16px 0 16px;
      @import "@/styles/Typography";
    }
  }
}

.event-message {
  p {
    margin: 0;
    line-height: normal;

    strong {
      @include Gilroy-Bold;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/styles/Typography";

.no-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  h4 {
    @include Gilroy-Bold;
    margin: 0 0 8px 0;
  }

  p {
    text-align: center;
    color: $strom;
    padding: 0 5%;
  }
}
h3 {
  margin-bottom: 24px;
}

.responsive-separator {
  display: none;
}

.audience {
  margin-left: auto;
  color: white;
  padding: 0px 10px;
  height: 24px;
  display: flex;
  align-items: center;
  background: $ocean;
  border-radius: 12px;
  text-transform: uppercase;
  @include Gilroy-Bold;
  letter-spacing: 1.2px;
  font-size: 12px;
  user-select: none;

  &.public {
    background: $cloud;
    color: $blizzard;
  }
  &.accepted {
    background: $sky;
  }
  &.pending {
    background: $sunset;
  }
}
.event-messages {
  padding: 0 12px;
}
.event-message {
  border: 1px solid $sleet;
  border-radius: 10px;
  &:not(:first-of-type) {
    margin-top: 32px;
  }

  .message-header {
    padding: 16px 18px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .read-marker {
    background: $twilight;
    width: 12px;
    height: 12px;
    border-radius: 6px;
  }

  .message-details {
    display: flex;
    border-top: 1px solid $sleet;
    background: $snow;
    padding: 12px 16px 14px 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;

    .read-toggle {
      cursor: pointer;
      color: $twilight;
    }

    .author-details {
      display: flex;
      flex-direction: column;
      color: $strom;

      .updater {
        margin-top: 4px;
      }
      .visibility {
        margin-top: 4px;
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .no-messages {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      margin: 0 0 24px 0;
    }

    p {
      color: $strom;
      padding: 0 20%;
    }
  }
  .event-message {
    .message-header {
      padding: 30px 30px 0 30px;
    }
    .message-body {
      padding: 12px 30px 0 30px;
      max-width: 70%;
    }
    .message-details {
      margin-top: 24px;
      padding: 18px 30px 20px 30px;

      .author-details {
        display: flex;
        flex-direction: row;
        width: 80%;

        p {
          white-space: nowrap;
        }

        .updater {
          margin: 0;
        }
        .visibility {
          margin: 0;
        }
      }

      .responsive-linebreak {
        display: none;
      }
    }

    .responsive-separator {
      display: block;
      margin: 0 8px;
    }
  }
}
</style>
